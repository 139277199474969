import React from "react";
import {graphql} from "gatsby";
import Shop from "../templates/shop";
import { SEO } from '../components/seo';


export default Shop;
export const pageQuery = graphql`
{ shopify{
  products(first: 100) {
    edges {
      node {
        id
        tags
        descriptionHtml
        handle
        title
        productType
        availableForSale
        variants(first: 50) {
          edges {
            node {
              id
              title
              priceV2 {
                amount
              }
            }
          }
        }
        metafields(identifiers: [{ key: "eng_name", namespace: "custom"}]) {
          id
          value
          key
          reference {
            ...on SHOPIFY_MediaImage{
              image {
                url
              }
            }
          }
        }
        images(first: 10) {
          edges {
            node {
              url(transform: { 
                crop: CENTER
                maxWidth: 500,
                maxHeight: 500})
              id
              thumbnailUrl: url(transform: { 
                crop: CENTER
                maxWidth: 360,
                maxHeight: 360})
            }
          }
        }
      }
    }
  }
}
}
`;
export const Head = () => (
  <SEO
    title="商店"
  />
)
